<template>
  <div>
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item label="FACE ID">
        <el-input v-model="dataForm.faceId" placeholder="faceId" clearable></el-input>
      </el-form-item>
      <el-form-item label="USER ID">
        <el-input v-model="dataForm.userId" placeholder="userId" clearable></el-input>
      </el-form-item>
      <el-form-item label="昵称">
        <el-input v-model="dataForm.nickname" placeholder="nickname" clearable></el-input>
      </el-form-item>
      <el-form-item label="主播UID">
        <el-input v-model="dataForm.anchorUid" placeholder="anchorUid" clearable></el-input>
      </el-form-item>

      <el-form-item label="创建日期">
        <el-date-picker v-model="dataForm.createTimeArr" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
                        :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                        align="right">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()" type="primary">搜索</el-button>
        <el-button @click="refresh()">重置</el-button>

        <el-button @click="addOrUpdateHandle(0)" type="primary">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="dataList" border v-loading="dataListLoading" style="width: 100%; ">

      <el-table-column prop="faceId" header-align="center" align="center" label="FACE ID">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.faceId ? '/' : scope.row.faceId }}
                    </span>
        </template>
      </el-table-column>
      <el-table-column prop="faceImgUrl" header-align="center" align="center" label="效果图" width="100">
        <template slot-scope="scope">
          <img style="width: 40px; height: 40px" :src="scope.row.faceImgUrl" />
        </template>
      </el-table-column>
      <el-table-column prop="anchorUid"  header-align="center" align="center" label="主播uid">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.anchorUid ? '/' : scope.row.anchorUid }}
                    </span>
        </template>
      </el-table-column>
      <el-table-column prop="id" header-align="center" align="center" label="主播ID">
        <template slot-scope="scope">
          {{ getAnchorInfo(scope.row.anchorUid,'userId') }}
        </template>
      </el-table-column>
      <el-table-column prop="nickname" header-align="center" align="center" label="主播昵称">
        <template slot-scope="scope">
          {{ getAnchorInfo(scope.row.anchorUid,'nickname') }}
        </template>
      </el-table-column>
      <el-table-column prop="agency" header-align="center" align="center" label="经纪公司">
        <template slot-scope="scope">
          {{ getAnchorInfo(scope.row.anchorUid,'agency') }}
        </template>
      </el-table-column>
      <el-table-column prop="avatar" header-align="center" align="center" label="头像">
        <template slot-scope="scope">

          <img style="width: 40px; height: 40px" :src="getAnchorInfo(scope.row.anchorUid,'avatar') " />
        </template>
      </el-table-column>
      <el-table-column prop="opName" header-align="center" align="center" label="操作人">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.opName ? '/' : scope.row.opName }}
                    </span>
        </template>
      </el-table-column>

      <el-table-column prop="status"  sortable header-align="center" align="center" label="发布状态">
        <template slot-scope="scope">
          <div>
            <el-tag v-if="scope.row.status === 0" type="primary">正常</el-tag>
            <el-tag v-if="scope.row.status === 1" type="info">失效</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="utime" header-align="center" align="center" label="创建时间">
      </el-table-column>
      <el-table-column fixed="right" header-align="center" align="center"  width="200"  label="操作">
        <template slot-scope="scope">
          <el-button type="text"   size="small" style="margin-right:10px" @click="addOrUpdateHandle(scope.row)" >编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
                   :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
                   layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <upload-anchor v-if="uploadAnchorVisible" ref="uploadAnchor" @refreshDataList="getDataList"></upload-anchor>
    <el-dialog title="IM对话管理" :visible.sync="imManageDialogVisible" width="94%" top="1vh">
      <ImManage :uid=currentLoginVirtualUid :ipList="allVirtualIpList">
      </ImManage>
    </el-dialog>
  </div>
</template>
<script>
import AddOrUpdate from "./anchor-beauty-add-or_update";
import { langOpt, pickerOptions } from "@/views/common/commonOpt";

const sourceDataForm = {
  id: '',
  faceId: '',
  faceImgUrl: '',
  anchorUid: '',
  opName:'',
  atime: '',
  utime: '',
  status:'',
  pullPort:'',
  pushPort:'',
  createTimeArr: []
}
export default {
  data() {
    return {
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
      dataList: [],
      pageIndex: 1,
      anchorList:[],
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      langOpt: langOpt,
      pickerOptions: pickerOptions,
      addOrUpdateVisible: false,
      uploadAnchorVisible:true,
      imManageDialogVisible: false,
      allVirtualIpList: [],
      currentLoginVirtualUid: -1
    }
  },
  components: {
    AddOrUpdate
  },
  created(){
    this.getAllAnchor();
  },
  activated() {
    this.getDataList()
  },
  methods: {
    refresh() {
      this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
      this.pageIndex = 1
      this.pageSize = 10
      this.getDataList()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/admin/anchorBeauty/list'),
        method: 'post',
        data: this.$http.adornData({
          ...this.dataForm,
          startTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
          endTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null,
          anchorUid: this.dataForm.anchorUid,
          page: this.pageIndex,
          limit: this.pageSize,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.result.data
          this.totalPage = data.result.last_page
        } else {
          this.$message.error(data.msg)
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    uploadAnchor(){
      this.uploadAnchorVisible = true
      this.$nextTick(() => {
        this.$refs.uploadAnchor.visible = true
        this.$refs.uploadAnchor.fileList = []
      })
    },
    getAnchorInfo(anchorUid,key){
      let ipOpt = this.anchorList.find(item => item['uid'] == anchorUid)
      return !ipOpt ? "" : ipOpt[key]
    },
    getAllAnchor() {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/admin/anchor/list'),
        method: 'post',
        data: this.$http.adornData({
          status:0,
          page:0,
          limit: 1000,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.anchorList = data.result.data
        } else {
          this.$message.error(data.msg)
        }
        this.dataListLoading = false
      })
    },
    // 导出
    exportList() {


      const exportData = {
        ...this.dataForm,
        startTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
        endTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null,
        uid: this.dataForm.uid,
        page: this.pageIndex,
        limit: this.pageSize,
      }
      const paramJson = encodeURI(JSON.stringify(exportData).replace(/\+/g, "%2b"))
      window.location.href = this.$http.adornUrl('/admin/anchor/export') + `?token=${this.$cookie.get('token')}&paramJson=${paramJson}`
    }
  },
}
</script>

<style scoped lang="scss">
.el-table {
  .el-button+.el-button {
    margin-left: 0;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep .el-tag--small {
  margin-right: 2px;
}
</style>
