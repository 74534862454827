<template>
  <div>
    <el-dialog width="60%" :title="!dataForm.id ? '新增' : '查看'" :close-on-click-modal="false" :visible.sync="visible"
               :before-close="handleCloseDialog">
      <el-form  :model="dataForm" ref="dataForm" label-width="160px">
        <el-form-item label="FACE ID" >
          <el-select v-model="dataForm.faceId" placeholder="FACE ID" @change="handleFaceIdChange">
            <el-option v-for="item in faceList" :key="item.faceId" :label="item.faceId" :value="String(item.faceId)">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="效果图：" prop="faceImgUrl">
           <img v-if="dataForm.faceImgUrl" :src="dataForm.faceImgUrl" class="avatar">
        </el-form-item>

        <el-form-item label="主播:" >
          <!--          <el-select v-model="dataForm.anchorUid" placeholder="主播">
                      <el-option v-for="item in anchorList" :key="item.uid" :label="item.nickname" :value="String(item.uid)">
                      </el-option>
                    </el-select>-->
          <el-select
              v-model="dataForm.anchorUid"
              filterable
              placeholder="主播"
              @visible-change="handleVisibleChange"
              clearable
          >
            <el-option
                v-for="item in filteredOptions"
                :key="item.uid" :label="item.nickname + '(' + item.uid + ')'"  :value="String(item.uid)">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态:" prop="status">
          <el-radio-group v-model="dataForm.status">
            <el-radio :label="0" >启用</el-radio>
            <el-radio :label="1">禁止</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
         <el-button @click="handleCloseDialog">取消</el-button>
         <el-button type="primary" @click="beforeSubmit()">确定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { langOpt } from "@/views/common/commonOpt";
export default {
  data() {
    return {
      id: '',
      visible: false,
      anchorList:[],
      filteredOptions:[],
      faceList:[],
      dataForm: {
        anchorUid: '',
        faceId: '',
        faceImgUrl: '',
        status: 0,
        id:0
      },
      langOpt: langOpt,
      dataRule: {

      },
    }
  },

  async created() {

    this.getAllAnchor();
    this.getAllFaceList()

  },
  watch: {
    value(newVal, oldVal) {
      // 当 value 改变时（即用户在搜索框中输入时），重新过滤选项
      this.filterOptions()
    },
  },
  methods: {
    init(row) {
      // console.log('row', row)
      if (this.$refs['dataForm']) this.$refs['dataForm'].resetFields()
      this.id = !row ? 0 : row.id
      this.dataForm.id = row.id
      if (row === 0) {
        this.dataForm.status = 0
        this.dataForm.anchorUid = ''
        this.dataForm.faceId = ''
        // 新增模式
        this.visible = true
        return;
      }

      this.visible = true
      this.dataForm.faceId = row.faceId
      this.dataForm.faceImgUrl = row.faceImgUrl
      this.dataForm.status = row.status
      this.dataForm.anchorUid = String(row.anchorUid)
      this.filterOptions()
    },
    handleFaceIdChange(value) {
      this.faceList.forEach(item => {
         if (item.faceId === value){
           this.dataForm.faceImgUrl = item.faceImgUrl
         }
      })
    },
    beforeSubmit() {
      this.$confirm('当前配置信息是否已确认无误?', '提示', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        this.dataFormSubmit();
      })
    },
    handleVisibleChange(val) {
      // 当下拉框的可见性改变时，如果变为可见，则重置过滤后的选项
      if (val) {
        this.filterOptions();
      }
    },
    filterOptions() {
      const searchText = this.dataForm.anchorUid.trim().toLowerCase();
      if (searchText) {
        this.filteredOptions = this.anchorList.filter(option => {
          return option.nickname.toLowerCase().includes(searchText) ||
              option.uid === this.dataForm.anchorUid;
        });
      } else {
        // 如果搜索文本为空，则显示所有选项
        this.filteredOptions = this.anchorList;
      }
    },
    getAllAnchor() {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/admin/anchor/list'),
        method: 'post',
        data: this.$http.adornData({
          status:0,
          page:0,
          limit: 1000,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.anchorList = data.result.data
        } else {
          this.$message.error(data.msg)
        }
        this.dataListLoading = false
      })
    },
    getAllFaceList() {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/admin/anchorBeauty/faceIdList'),
        method: 'post',
        data: this.$http.adornData({
          status:0,
          page:0,
          limit: 1000,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.faceList = data.result
        } else {
          this.$message.error(data.msg)
        }
        this.dataListLoading = false
      })
    },
    handleCloseDialog() {
      this.visible = false
      this.imageList = []
    },

    getAnchorInfo(anchorUid,key){
      let ipOpt = this.anchorList.find(item => item['uid'] == anchorUid)
      return !ipOpt ? "" : ipOpt[key]
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {

          this.$http({
            url: this.$http.adornUrl(`/admin/anchorBeauty/addFaceIdAnchor`),
            method: 'post',
            data: this.$http.adornData({
              ...this.dataForm,
              id: this.id
            })
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.input-width {
  width: 35vw;
  margin-bottom: 2vh;
}
</style>